<template>
  <div class="matchList-shortcut-wrapper">
    <transition name="shortcut">
      <template v-if="isMobile && showShortcut">
        <div>
          <div
            class="matchList-shortcut-tab"
            :class="{ 'shortcut-active': s === currentTab }"
            v-for="s in shortcut"
            :key="s"
            @click="scrollTo(s)"
          >
            {{ $t(s) }}
          </div>
        </div>
      </template>
    </transition>
    <template v-if="!isMobile">
      <div>
        <div
          class="matchList-shortcut-tab"
          :class="{ 'shortcut-active': s === currentTab }"
          v-for="s in shortcut"
          :key="s"
          @click="scrollTo(s)"
        >
          {{ $t(s) }}
        </div>
      </div>
    </template>
    <div
      class="matchList-shortcut-tab show-shortcut"
      v-if="isMobile"
      @click="mobileShortcutHandler"
      :style="showShortcut ? { opacity: '1' } : { opacity: '.5' }"
    >
      <img
        :src="
          showShortcut
            ? require('../../../../../../static/images/icons/icon_arrow_right.png')
            : require('../../../../../../static/images/icons/icon_arrow_left.png')
        "
        alt=""
      />
    </div>
  </div>
  <section id="TEAM">
    <LeagueStanding @apiCallsDone="handlerAPICallsDone('LeagueStanding')" />
    <LastNMatchesStat @apiCallsDone="handlerAPICallsDone('LastNMatchesStat')" />
    <SquadStatus @apiCallsDone="handlerAPICallsDone('SquadStatus')" />
    <PlayerPerformanceRating @apiCallsDone="handlerAPICallsDone('PlayerPerformanceRating')" />
  </section>
  <section id="HISTORY">
    <OddsResult side="h2h" :matchDetailScore="matchDetailScore" @apiCallsDone="handlerAPICallsDone('OddsResult')" />
    <OddsResult side="home" :matchDetailScore="matchDetailScore" @apiCallsDone="handlerAPICallsDone('OddsResult')" />
    <OddsResult side="away" :matchDetailScore="matchDetailScore" @apiCallsDone="handlerAPICallsDone('OddsResult')" />
    <keep-alive>
      <router-view
        v-once
        :key="$route.name"
        @selectCompany="companySelectHandler"
        @selectDuration="durationSelectHandler"
      />
    </keep-alive>
  </section>
  <section id="PROBABILITY">
    <GoalScoringStat :matchDetailScore="matchDetailScore" @apiCallsDone="handlerAPICallsDone('GoalScoringStat')" />
    <CurrentSeasonStat @apiCallsDone="handlerAPICallsDone('CurrentSeasonStat')" />
  </section>
  <section id="ANALYSIS_INFO">
    <MediaPredict @apiCallsDone="handlerAPICallsDone('MediaPredict')" />
    <Next5Match @apiCallsDone="handlerAPICallsDone('Next5Match')" />
    <!-- <MatchStats @apiCallsDone="handlerAPICallsDone('MatchStats')" /> -->
  </section>
</template>

<script>
import GoalScoringStat from "@/components/matchListings/analysis/GoalScoringStat.vue";
import LeagueStanding from "@/components/matchListings/analysis/LeagueStanding.vue";
import Next5Match from "@/components/matchListings/analysis/Next5Match.vue";
import SquadStatus from "@/components/matchListings/analysis/SquadStatus.vue";
import PlayerPerformanceRating from "@/components/matchListings/analysis/PlayerPerformanceRating.vue";
import OddsResult from "@/components/matchListings/analysis/OddsResult.vue";
import LastNMatchesStat from "@/components/matchListings/analysis/LastNMatchesStat.vue";
import MediaPredict from "@/components/matchListings/analysis/MediaPredict.vue";
// import MatchStats from "@/components/matchListings/analysis/MatchStats.vue";
import CurrentSeasonStat from "@/components/matchListings/analysis/CurrentSeasonStat.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    GoalScoringStat,
    Next5Match,
    LeagueStanding,
    SquadStatus,
    PlayerPerformanceRating,
    OddsResult,
    LastNMatchesStat,
    MediaPredict,
    // MatchStats,
    CurrentSeasonStat,
  },
  props: ["matchDetailScore"],
  data() {
    return {
      shortcut: ["TEAM", "HISTORY", "PROBABILITY", "ANALYSIS_INFO"],
      showShortcut: false,
      currentTab: "",
      apiCallsDone: {
        LeagueStanding: false,
        LastNMatchesStat: false,
        SquadStatus: false,
        PlayerPerformanceRating: false,
        OddsResult: false,
        GoalScoringStat: false,
        CurrentSeasonStat: false,
        MediaPredict: false,
        Next5Match: false,
        // MatchStats: false,
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollActive);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrollActive);
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  methods: {
    ...mapMutations([
      "MANUAL_REFRESH_MATCH_DETAIL",
    ]),
    scrollTo(pos) {
      // document.getElementById(pos).scrollIntoView({ behavior: "smooth" }, true);

      // console.log(document.getElementById(pos).offsetTop, window.scrollY);

      window.scrollTo({
        top:
          pos === "PROBABILITY"
            ? // ? this.isMobile
              document.getElementById(pos).offsetTop +
              (this.isMobile ? 640 : 600)
            : // : document.getElementById(pos).offsetTop + 600
              document.getElementById(pos).offsetTop - (this.isMobile ? 40 : 0),
        behavior: "smooth",
      });
      // if (pos === "PROBABILITY" && this.isMobile) {
      //   window.scrollBy(0, 430);
      // }
      // if (pos === "PROBABILITY") {
      //   window.scrollBy(0, 350);
      // }
    },
    mobileShortcutHandler() {
      this.showShortcut = !this.showShortcut;
    },
    scrollActive() {
      let team = document.getElementById("TEAM").offsetTop - 40;
      // let history = document.getElementById("HISTORY").offsetTop - 40; AS-1994
      let prob = document.getElementById("PROBABILITY").offsetTop - 40;
      let info = document.getElementById("ANALYSIS_INFO").offsetTop - 40;

      if (window.scrollY >= team) {
        this.currentTab = "TEAM";
      }
      // if (window.scrollY >= history) {
      //   this.currentTab = "HISTORY";
      // }
      if (window.scrollY >= prob + 350) {
        this.currentTab = "PROBABILITY";
      }
      if (window.scrollY >= info - 500) {
        this.currentTab = "ANALYSIS_INFO";
      }
      // console.log(team, history, prob, info);
    },
    handlerAPICallsDone(key) {
      this.apiCallsDone[key] = true;
      this.verifyIfAllAPICallsDone();
    },
    verifyIfAllAPICallsDone() {
      let goodToGo = Object.values(this.apiCallsDone);
      if (!goodToGo.includes(false)) {
        this.MANUAL_REFRESH_MATCH_DETAIL();
        // once all all done, then revert all back to initial state (false)
        for (const [key, val] of Object.entries(this.apiCallsDone)) {
          this.apiCallsDone[key] = false;
        }
      }
    },
  },
};
</script>

<style>
section {
  margin: 1.5rem auto;
  scroll-margin: 20px;
}
.matchList-container {
  width: 100%;
}
.matchList-shortcut-wrapper {
  position: fixed;
  right: 0;
  top: 12rem;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.matchList-shortcut-tab img {
  width: 8px;
  height: 12px;
}
.matchList-shortcut-tab.shortcut-active {
  background: #2e8fef;
  color: #fff;
}
.matchList-shortcut-tab {
  background: #01c3f7;
  color: #000;
  border-radius: 4px 0px 0px 4px;
  height: 2.25rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.25rem 0;
  cursor: pointer;
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: 700;
}
.matchList-content_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
}

.matchList-content_table {
  font-size: 0.625rem;
  line-height: 0.875rem;
  background: #35454d;
  border-collapse: collapse;
  width: 48%;
  text-align: center;
}

.matchList-content_table {
  font-size: 0.625rem;
  line-height: 0.875rem;
  background: #35454d;
  border-collapse: collapse;
  text-align: center;
}

.table-half {
  width: 48%;
}

.table-full {
  width: 100%;
}
.matchList-content_table-title {
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding: 0.875rem 0 !important;
}

.matchList-content_title {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0.625rem 0 0.625rem 1rem;
}

.matchList-content_table-subtitle th:nth-child(2),
.matchList-content_table-subtitle th:nth-child(3) {
  width: 35%;
}
.matchList-content_table th {
  font-weight: 700;
}
.matchList-content_table th,
.matchList-content_table td {
  border: 0.5px solid #4b616c;
  padding: 0.625rem 0.25rem;
}

.matchList-content_separator {
  font-weight: 700;
  background: #35454d;
}

.matchList-content_table tbody {
  background: #212b30;
}

.matchList-content_table td.no-stat {
  padding: 0.625rem 0.25rem !important;
  font-size: 0.625rem;
  line-height: 0.875rem;
}

@media (max-width: 768px) {
  section {
    scroll-margin: 50px;
  }
  .matchList-container {
    width: 95%;
  }
  .matchList-content_table {
    empty-cells: show;
  }
  .matchList-content_table-title,
  .matchList-content_title {
    font-size: 0.75rem;
    font-weight: 700;
    text-indent: unset;
  }
  .matchList-content_title {
    margin: 0.625rem 0;
  }

  .matchList-content_table .matchList-content_table-title {
    padding: 0.625rem 0;
  }
  .matchList-content_table th {
    border: 0.75px solid #4b616c;
    padding: 0.35rem 0;
    background: #35454d;
  }
  .matchList-content_table td {
    border: 0.75px solid #4b616c;
    padding: 1rem 0;
  }
  .matchList-tab {
    display: flex;
  }
  .matchList-tab button {
    background: none;
    color: #6f8c9b;
    border: 0;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    position: relative;
    padding: 0 0 6px 0;
    margin-right: 1rem;
  }
  .matchList-tab button:hover,
  .matchList-tab button.tab-selected {
    color: #fff;
  }
  .matchList-tab button.tab-selected::after,
  .matchList-tab button:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: #6f8c9b;
    border-radius: 2px;
  }
  .matchList-content_table {
    border-radius: 0.5rem;
    overflow: hidden;
  }
  .matchList-content_table tfoot td {
    padding: 0 !important;
    height: 1.875rem;
  }
  .matchList-content_separator button {
    background: #35454d;
    border: 1px solid #4b616c;
    border-radius: 8px;
    color: #fff;
    width: 95%;
    cursor: pointer;
    padding: 0.3rem 0;
    font-weight: 700;
    font-size: 0.75rem;
  }
  .matchList-content_separator button.active {
    background: #4b616c;
  }
  .matchList-shortcut-wrapper {
    position: fixed;
    right: 0;
    top: unset;
    bottom: 12rem;
  }
  .show-shortcut {
    height: 2.25rem;
    width: 3rem;
    margin-right: 0;
  }
  /* transition */
  .shortcut-enter-active,
  .shortcut-leave-active {
    transition: all 1s ease;
  }

  .shortcut-enter-from,
  .shortcut-leave-to {
    transform: translateX(5rem);
  }
}
</style>
